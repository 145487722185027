import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
// const slugify = require("slugify")

const Blogs = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO
        title="Blog"
        description="We constantly keep ourselves updated about industry developments and we share this knowledge with you through the JKA Design Blog. Read our posts here."
      />
      <div class="page-headline">
        <div class="container">
          <div class="section-heading text-center">
            <h6 class="font-weight-bold text-uppercase flair">Blog</h6>
            <h1>
              <strong>Latest Posts</strong>
            </h1>
          </div>
        </div>
      </div>
      <div class="blog-section">
        <Container>
          <div class="row justify-content-between">
            {data.allSanityPost.edges.map(blog => (
              <div class="col-md-4 mb-5">
                <div class="h-100 blog-inner shadow-sm" key={blog}>
                  <Link to={blog.node.slug.current}>
                    <div
                      class="blog-image"
                      style={{
                        backgroundImage: `url(${
                          blog.node.mainImage !== null
                            ? blog.node.mainImage.asset.fluid.src
                            : "https://source.unsplash.com/user/joshhild/500x500"
                        })`,
                      }}
                    />
                  </Link>
                  <div class="blog-content py-3 px-4">
                    <Link to={blog.node.slug.current}>
                      <h4>{blog.node.title}</h4>
                    </Link>
                    <div class="text-muted small">
                      {blog &&
                      blog.node &&
                      blog.node.categories &&
                      blog.node.categories.length !== 0
                        ? blog.node.categories.map(ct => (
                            <span>
                              <i class="fa fa-folder pr-1" />

                              <Link to="/">{ct.title + " "}</Link>
                            </span>
                          ))
                        : null}
                    </div>
                    <p class="pt-2 text-muted">{blog.node.excerpt}</p>
                    <div class="blog-item-author-image d-flex align-items-center justify-content-start">
                      <span class="author-img mr-2">
                        <img
                          alt={blog.node.authors[0].person.name}
                          class="rounded-circle"
                          src={
                            blog.node.authors[0].person.image.asset.fluid.src
                          }
                          height="32"
                          width="32"
                        />
                      </span>
                      <div class="blog-item-authorname-date">
                        <span class="text-muted small d-block">
                          {blog.node.authors[0].person.name}
                        </span>
                        <span class="text-muted small">
                          <i class="fa fa-calendar-o pr-1" />
                          {blog.node.publishedAt}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*<div class="col-md-4 mb-4">
							<div class="side-content">
								<h6 class="text-uppercase text-muted">Categories</h6>
								<ul class="list-unstyled">
									{data.allSanityPost.group.map(cat => (
										<li key={cat.fieldValue}>
											<Link
												class="text-body font-weight-bold"
												to={slugify(cat.fieldValue.toLowerCase())}
											>
												{cat.fieldValue}
											</Link>
										</li>
									))}
								</ul>
							</div>
							<div class="side-content">
								<h6 class="text-uppercase text-muted">Keep Up-to-Date</h6>
								<p class="small">
									Get our latest news and updates straight to your inbox. Enter
									your email address to subscribe:
								</p>
								<form>
									<div class="form-group">
										<input
											class="form-control"
											type="email"
											id="email"
											required=""
										/>
										<label htmlFor="email">Email address</label>
									</div>
									<div class="form-group mb-4">
										<button class="btn btn-primary" type="submit">
											Subscribe
										</button>
									</div>
								</form>
							</div>
						</div>*/}
        </Container>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          authors {
            person {
              id
              name
              image {
                asset {
                  id
                  fluid {
                    src
                  }
                }
              }
            }
          }
          excerpt
          slug {
            current
          }
          body
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
